import { SxProps, Theme } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { deDE, enUS, frFR } from "@mui/x-date-pickers/locales";
import { useDateLocale } from "@recare/core/hooks/useDateLocale";
import { InputWidthType, MUIPickersDate } from "@recare/core/types";
import {
  FormElement,
  FormElementProps,
  isValid,
} from "@recare/react-forms-state";
import { useTranslations } from "@recare/translations";
import { fromUnixTime, getUnixTime, set } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { TextInputFieldProps } from "ds/components/TextInputField";
import { GREY_600 } from "ds/materials/colors";
import { Z_INDEX_DRAWER_CONTENT, inputWidth } from "ds/materials/metrics";
import { FONT_SIZE_14 } from "ds/materials/typography";
import { getHelperText } from "../Validation";

export type ConnectedDateSelectorProps = FormElementProps &
  Omit<DateSelectorProps, "onChange">;

export type DateSelectorProps = {
  ariaLabel?: string;
  ariaLabelledBy?: string;
  blockAfter?: number;
  blockBefore?: number;
  disablePast?: boolean | null | undefined;
  elementName?: string;
  fullWidth?: boolean;
  hasCustomValidation?: boolean;
  id?: string;
  inputSx?: SxProps<Theme>;
  inputWidthType?: InputWidthType | undefined;
  label?: string;
  onChange: (v: number | null) => void;
  placeholder?: string;
  required?: boolean;
  showToolbar?: boolean;
  sx?: SxProps<Theme>;
  textInputVariant?: TextInputFieldProps["variant"];
  validation?: FormElementProps["validation"];
  value?: number | null;
};

export function getLocaleText(value: string | undefined) {
  let locale = enUS;
  if (value === "de") {
    locale = deDE;
  } else if (value === "fr") {
    locale = frFR;
  }

  return locale.components.MuiLocalizationProvider.defaultProps.localeText;
}

export const isValidDate = (dateValue: number | null) => {
  if (!dateValue) return false;
  const selectedDate = fromUnixTime(dateValue);

  return isValid(selectedDate) && selectedDate.getFullYear() > 1900;
};

export function DatePickerInputField({
  ariaLabel,
  ariaLabelledBy,
  blockAfter,
  blockBefore,
  disablePast,
  elementName,
  fullWidth = false,
  hasCustomValidation,
  id,
  inputSx,
  inputWidthType,
  label,
  onChange,
  placeholder,
  required,
  showToolbar = false,
  sx,
  textInputVariant = "standard",
  validation,
  value,
}: DateSelectorProps) {
  const { currentLocale } = useDateLocale();
  const translations = useTranslations();
  const pickerInputWidth = inputWidth(inputWidthType);
  const maxDate = blockAfter ? fromUnixTime(blockAfter) : undefined;
  const minDate =
    blockBefore || disablePast
      ? blockBefore
        ? fromUnixTime(blockBefore)
        : new Date()
      : undefined;

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={currentLocale}
      localeText={getLocaleText(currentLocale.code)}
    >
      <DesktopDatePicker
        disablePast={!!disablePast}
        maxDate={maxDate}
        minDate={minDate}
        onChange={(date: MUIPickersDate) => {
          if (!date) {
            return onChange(null);
          }
          // date picker is not intended to set time
          // normalize to 12 pm (midday)
          // this ensures timezones on start/end of days don't change the day
          // important e.g. for day filtering logic in the BE
          const normalizedDate = zonedTimeToUtc(
            set(date, { hours: 12 }),
            "UTC",
          );
          const normalizedUnixTime = getUnixTime(normalizedDate);
          if (!isValidDate(normalizedUnixTime)) {
            return onChange(null);
          }

          onChange(normalizedUnixTime);
          return normalizedUnixTime;
        }}
        slotProps={{
          openPickerButton: {
            ...({
              "aria-hidden": true, // for accessibility reasons
              "data-testid": `${elementName}_open`,
              tabIndex: -1, // for accessibility reasons
            } as any),
          },
          popper: { style: { zIndex: Z_INDEX_DRAWER_CONTENT } },
          textField: {
            error: !isValid(validation),
            fullWidth,
            helperText: getHelperText({
              hasCustomValidation: !!hasCustomValidation,
              translations,
              validation,
            }),
            id: id ?? elementName,
            InputLabelProps: {
              style: { fontSize: FONT_SIZE_14 },
            },
            inputProps: {
              "aria-label": ariaLabel,
              "aria-labelledby": ariaLabelledBy,
              "data-testid": `input_text_${elementName}`,
              placeholder: translations.patient.dateOfBirthFormat,
              style: {
                fontSize: FONT_SIZE_14,
              },
            },
            label,
            placeholder,
            required,
            sx: {
              width: pickerInputWidth,
              "input::placeholder": { color: GREY_600, opacity: 1 },
              ...inputSx,
            },
            variant: textInputVariant,
          },
          toolbar: { hidden: !showToolbar },
        }}
        sx={sx}
        value={value && isValidDate(value) ? fromUnixTime(value) : null}
      />
    </LocalizationProvider>
  );
}

const ConnectedFileInputField =
  FormElement()<ConnectedDateSelectorProps>(DatePickerInputField);

export default ConnectedFileInputField;
